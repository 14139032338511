import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors as c } from "../style";

const SectionTitle = styled.h2`
  text-align: ${props => (props.leftAlign ? `left` : `center`)};
  font-size: 2.3rem;
  color: ${c.section_heading};
  font-weight: 400;
  line-height: 3rem;
  width: auto;
`;

const WideContainer = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : "inherit")};
  color: gray;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  ${props => {
    if (props.bgImage) {
      return `
        background-color: inherit;
        background: url(${props.bgImage});
        background-size: cover;
        background-position: center;
        z-index: -100;
        min-height: ${props.minHeight || "auto"}
      `;
    }
  }}
`;

const SlimSection = styled.section`
  max-width: ${props => (props.wider ? `1350px` : `1100px`)};
  margin: 0 2rem;
  text-align: left;
  @media all and (min-width: 1350px) {
    margin: 0rem auto;
    padding: ${props => (props.short ? `padding: 0` : `1rem 0`)};
  }
`;

const ResponsiveWidthContainer = ({
  bgColor,
  bgImage,
  minHeight,
  style,
  children,
  className
}) => {
  return (
    <WideContainer
      bgColor={bgColor}
      bgImage={bgImage}
      minHeight={minHeight}
      className={className}
    >
      <SlimSection css={style}>{children}</SlimSection>
    </WideContainer>
  );
};

ResponsiveWidthContainer.propTypes = {
  bgColor: PropTypes.string,
  bgImage: PropTypes.string,
  minHeight: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node
};

export { SectionTitle, WideContainer, SlimSection, ResponsiveWidthContainer };
