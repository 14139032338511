import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";

const HelmetHead = ({ pageMetaData, location }) => {
  const data = useStaticQuery(graphql`
    query HeadingQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const ogData = {
    title:
      (pageMetaData.title &&
        `${pageMetaData.title} — ${data.site.siteMetadata.title}`) ||
      data.site.siteMetadata.title,
    image:
      `${location.origin +
        (pageMetaData &&
          pageMetaData.featureimage.childImageSharp.fixed.src)}` || ""
  };

  return (
    <Helmet>
      <html lang="en" />
      <title>{data.site.siteMetadata.title}</title>
      <meta name="description" content={data.site.siteMetadata.description} />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/img/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        href="/img/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="/img/favicon-16x16.png"
        sizes="16x16"
      />

      <link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#ff4400" />
      <meta name="theme-color" content="#fff" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={ogData.title} />
      <meta property="og:url" content={location.href} />
      <meta property="og:image" content={ogData.image} />

      <script src="/moment.min.js" type="text/javascript" defer />
      <script src="/iziToast.min.js" type="text/javascript" defer />

      <link rel="stylesheet" href="/iziToast.min.css" />
      <link rel="stylesheet" href="/iziToast-styles.css" />
      <script src="/iziToast_init.js" type="text/javascript" defer />
    </Helmet>
  );
};

HelmetHead.propTypes = {
  pageMetaData: PropTypes.object,
  location: PropTypes.object
};

export default HelmetHead;
