import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import { ResponsiveWidthContainer } from "./Section";
import { SocialLinks } from "./SocialIcons";
import { colors as c } from "../style";

const StyledLink = styled(Link)`
  color: currentColor;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const headerContainerStyle = `
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;  
 
  @media all and (min-width: 480px) {
    font-size: 1.2rem;
  }
`;

const navStyle = `
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: fit-content;
  font-size: 1.5rem;
  margin: 3rem auto 2rem 3rem;
  @media all and (min-width: 480px) {
    font-size: 1.2rem;
    flex-flow: row nowrap;
    width: auto;
    margin: unset;
    margin-top: 2rem;
  }
`;

const StyledLinkSection = styled.div`
  div,
  ul {
    text-align: left;
  }
  div {
    margin-bottom: 1rem;
  }

  ul {
    padding: 0rem 0 1rem 0;
    color: ${c.text_main_sub};
    li {
      margin-bottom: 1rem;
    }
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: ${c.text_main};

  @media all and (min-width: 480px) {
    flex-flow: row nowrap;
  }

  > * {
    margin-bottom: 1rem;
  }
`;

const CopyrightNotice = styled.small`
  order: 1;
  @media all and (min-width: 480px) {
    order: 0;
  }
`;

const Header = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allFooterYaml {
        edges {
          node {
            title
            links {
              text
              link
            }
          }
        }
      }
      linksYaml {
        copyright
      }
    }
  `);

  const siteNavLinks = data.allFooterYaml.edges
    .map(x => x.node)
    .filter(x => x.title !== "SmallPrint");

  const termsAndPrivacyLinks = data.allFooterYaml.edges
    .map(x => x.node)
    .filter(x => x.title === "SmallPrint")[0].links;

  return (
    <ResponsiveWidthContainer bgColor={`${c.bg_section_alt}`}>
      <footer css={headerContainerStyle}>
        <nav css={navStyle}>
          {siteNavLinks.map(section => (
            <StyledLinkSection key={section.title}>
              <h4>{section.title}</h4>
              <ul>
                {section.links.map(x => (
                  <li key={x.text}>
                    <StyledLink to={x.link}>{x.text}</StyledLink>
                  </li>
                ))}
              </ul>
            </StyledLinkSection>
          ))}
        </nav>
        <ColumnContainer>
          <SocialLinks />
          <CopyrightNotice>{data.linksYaml.copyright}</CopyrightNotice>
          <small>
            {termsAndPrivacyLinks.map((item, index) => (
              <span key={item.text}>
                <StyledLink to={item.link}>{item.text}</StyledLink>
                {index < termsAndPrivacyLinks.length - 1 && " | "}
              </span>
            ))}
          </small>
        </ColumnContainer>
      </footer>
    </ResponsiveWidthContainer>
  );
};

Header.propTypes = {
  children: PropTypes.any
};

export default Header;
