import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";
import Image from "gatsby-image";
import styled from "styled-components";
import { WideContainer } from "./Section";

const navStyle = `
  margin: 0 1rem;
  padding-top: 2rem;
  flex-grow: 1;
  text-align: left;
  ul {
    margin: 0;
    li {
      display: block;
      margin-bottom: 1rem;
    }
  }
`;

const navLinkStyle = `
  align-self: flex-end;
  text-decoration: none;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  
  &:visited {
    color: white;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const menuItemsContainerStyle = `
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  height: 60vh;
`;

const logoStyle = `
  margin: 3rem auto;
`;

const FullHeightContainer = styled(WideContainer)`
  height: 100%;
`;

const Menu = ({ closeHandler }) => (
  <StaticQuery
    query={graphql`
      query MenuQuery {
        allHeaderYaml {
          edges {
            node {
              text
              link
            }
          }
        }
        allMarkdownRemark(
          filter: { frontmatter: { title: { eq: "YourSolarQuotes" } } }
        ) {
          edges {
            node {
              frontmatter {
                headerimage {
                  img {
                    childImageSharp {
                      fixed(width: 250) {
                        ...GatsbyImageSharpFixed_noBase64
                      }
                    }
                  }
                  alt
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <FullHeightContainer bgColor="#02bbe7">
        <div css={menuItemsContainerStyle}>
          <Link to="/" onClick={closeHandler}>
            <Image
              css={logoStyle}
              fixed={
                data.allMarkdownRemark.edges[0].node.frontmatter.headerimage.img
                  .childImageSharp.fixed
              }
              alt={
                data.allMarkdownRemark.edges[0].node.frontmatter.headerimage.alt
              }
            />
          </Link>
          <nav css={navStyle}>
            <ul>
              {data.allHeaderYaml.edges.map(x => (
                <li key={x.node.text}>
                  <Link css={navLinkStyle} to={x.node.link}>
                    {x.node.text}
                  </Link>
                </li>
              ))}
            </ul>{" "}
          </nav>
        </div>
      </FullHeightContainer>
    )}
  />
);

Menu.propTypes = {
  closeHandler: PropTypes.func
};

export default Menu;
