import React from "react";
import PropTypes from "prop-types";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { slide as Slider } from "react-burger-menu";
import HelmetHead from "../components/HelmetHead";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MyMenu from "../components/Menu";

import sanitize from "../style";

const GlobalStyle = createGlobalStyle`
  ${sanitize}
  h2 {
    font-weight: normal;
  }
`;

const defaultPageMetaData = {
  alt: "home battery storage your solar quotes solar news blog",
  featureimage: {
    childImageSharp: {
      fixed: {
        src: "/img/your-solar-quotes-bgimage.jpg"
      }
    }
  },
  title: "Your Solar Quotes",
  siteTitle: "Your Solar Quotes"
};

const theme = {
  primary: "#ffa726",
  secondary: "#02bbe7",
  primary_alt: "#ffffff",
  secondary_alt: "#ffffff",
  basic: "#808080",
  basic_alt: "#ffffff",
  transparent: "transparent",
  transparent_alt: "#ffffff"
};

class TemplateWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { scrolled: false, showMenu: false };
    this.showMenu = this.showMenu.bind(this);
    this.setScrolled = this.setScrolled.bind(this);
  }

  showMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  setScrolled() {
    this.setState({ scrolled: window.scrollY > 100 });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.setScrolled);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.setScrolled);
  }

  render() {
    const { headerOpts: { phoneOnly = false } = {} } = this.props;
    const pageMetaData = this.props.pageData || defaultPageMetaData;
    return (
      <ThemeProvider theme={theme}>
        <div id="outer-container">
          <HelmetHead
            pageMetaData={pageMetaData}
            location={this.props.location}
          />
          <GlobalStyle />
          <Header
            showContact={phoneOnly || this.state.scrolled}
            menuButtonHandler={this.showMenu}
          />
          <Slider
            right
            style={styles}
            isOpen={this.state.showMenu}
            width={"100%"}
            customBurgerIcon={false}
          >
            <MyMenu closeHandler={this.showMenu} />
          </Slider>
          <main id="page-wrap">{this.props.children}</main>
          <Footer />
        </div>
      </ThemeProvider>
    );
  }
}

export default TemplateWrapper;

TemplateWrapper.propTypes = {
  headerOpts: PropTypes.object,
  pageData: PropTypes.object,
  location: PropTypes.object,
  children: PropTypes.node
};

var styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    left: "36px",
    top: "36px"
  },
  bmBurgerBars: {
    background: "#373a47"
  },
  bmBurgerBarsHover: {
    background: "#a90000"
  },
  bmCrossButton: {
    height: "24px",
    width: "24px"
  },
  bmCross: {
    background: "#bdc3c7"
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%"
  },
  bmMenu: {
    background: "#373a47",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em"
  },
  bmMorphShape: {
    fill: "#373a47"
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em"
  },
  bmItem: {
    display: "inline-block"
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)"
  }
};
