import React from "react";
import PropTypes from "prop-types";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaEnvelope,
  FaYoutube
} from "react-icons/fa";

const icons = {
  Facebook: FaFacebook,
  Twitter: FaTwitter,
  Linkedin: FaLinkedin,
  Email: FaEnvelope,
  Youtube: FaYoutube
};

const SocialIconLink = ({ brand, link }) => {
  const Icon = icons[brand];
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Icon alt={brand} />
    </a>
  );
};

SocialIconLink.propTypes = {
  brand: PropTypes.string,
  link: PropTypes.string
};

export { SocialIconLink };
