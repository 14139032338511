import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { darken } from "polished";

const marginProperty = css`
  margin: ${props => (props.m ? props.m : "20px 0;")};
`;

const largePropertyStyle = css`
  font-size: 2rem;
  max-height: 70px;
`;

const largeProperty = css`
  ${props => props.large && largePropertyStyle}
`;

const outlinePropertyStyle = css`
  color: ${props => props.theme[props.color]};
  background-color: ${props => props.theme[props.color + "_alt"]};
  border-color: ${props => props.theme[props.color]};
  &:hover {
    background-color: ${props => props.theme[props.color]};
    color: ${props => props.theme[props.color + "_alt"]};
    border-color: ${props => props.theme[props.color]};
  }
`;

const outlineProperty = css`
  ${props => props.outline && outlinePropertyStyle}
`;

const transparentPropertyStyle = css`
  background-color: transparent;
  border-color: ${props => props.theme[props.color + "_alt"]};

  &:hover {
    color: ${props => props.theme[props.color]};
    background-color: ${props => props.theme[props.color + "_alt"]};
    border-color: ${props => props.theme[props.color + "_alt"]};
  }
`;

const transparentProperty = css`
  ${props => props.transparent && transparentPropertyStyle}
`;

const defaultStyles = css`
  outline: none;
  padding: 10px 20px;
  border: 2px solid ${props => props.theme[props.color]};
  border-radius: 12px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  max-height: 50px;

  &:hover {
    background-color: ${props => darken(0.1, props.theme[props.color])};
    border-color: ${props => darken(0.1, props.theme[props.color])};
  }

  color: ${props => props.theme[props.color + "_alt"]};
  background-color: ${props => props.theme[props.color]};
  border-color: ${props => props.theme[props.color]};

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const DefaultButton = styled.button`
  ${defaultStyles}
`;

DefaultButton.defaultProps = {
  color: "primary"
};

const Button = styled(DefaultButton)`
  ${largeProperty}
  ${outlineProperty}
  ${marginProperty}
  ${transparentProperty}
`;

Button.defaultProps = {
  color: "primary",
  large: false,
  outline: false
};

const DownloadButton = ({ fileUrl, className, children, ...rest }) => {
  const fileName = fileUrl.substr(fileUrl.lastIndexOf("/") + 1);
  return (
    <Button
      as="a"
      href={fileUrl}
      download={fileName}
      className={className}
      {...rest}
    >
      {children}
    </Button>
  );
};

DownloadButton.propTypes = {
  fileUrl: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

const ButtonLink = styled(Link)`
  ${defaultStyles}
  ${largeProperty}
  ${outlineProperty}
  ${marginProperty}
  ${transparentProperty}
`;

export { Button, DownloadButton, ButtonLink };
