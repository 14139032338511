/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import Image from "gatsby-image";
import styled from "styled-components";
import { ResponsiveWidthContainer } from "./Section";
import Phone from "./Phone";
import { FaBars } from "react-icons/fa";
import { Button } from "../components/Buttons";

const StyledHeaderContainer = styled(ResponsiveWidthContainer)`
  position: sticky;
  top: 0;
  z-index: 1;

  > section {
    margin: 0 auto;
    padding: 0;
    max-width: 1350px;
    padding: 0 1rem;
    }
  }
`;

const headerContainerStyle = `
  height: 70px;
  color: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0;

  @media (max-width: 700px) {
    justify-content: center;
  }

  a {
    /* Recenter logo */
    line-height: 0;
  }
`;

const navStyle = `
  @media (max-width: 700px) {
    display: none;
  }

  margin: 0 1rem;
  flex-grow: 1;
  text-align: right;
`;

const navLinkStyle = `
  align-self: flex-end;
  margin-left: 2rem;
  text-decoration: none;
  color: white;
  &:visited {
    color: white;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const ContactNumber = () => {
  const containerStyle = `
    @media (max-width: 700px) {
      display: none;
    }
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
    align-items: flex-end;
  `;

  const innerContainerStyle = `
    text-align: center;
  `;

  const timelineStyle = `
    font-size: 0.8rem;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 5px;
  `;

  const phoneStyle = `
    font-size: 1.5rem;
    margin: 0 13px 0 3px;
    display: flex;
    justify-content: flex-end;
  `;

  const phoneNumberStyle = `
    font-size: 1.3rem;
    font-weight: bold;
  `;

  return (
    <div css={containerStyle}>
      <Phone css={phoneStyle} />
      <div css={innerContainerStyle}>
        <div css={timelineStyle}>9am to 6pm AEST</div>
        <div css={phoneNumberStyle}>1300 660 848</div>
      </div>
    </div>
  );
};

const Or = () => {
  const containerStyle = `
    display: none;
    @media (max-width: 700px) {
      display: none;
    }
    @media (min-width: 960px) {
      display: flex;
    }
    justify-content: flex-end;
    margin-right: 2rem;
    align-items: flex-end;
  `;

  const innerContainerStyle = `
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
  `;

  return (
    <div css={containerStyle}>
      <div css={innerContainerStyle}>or</div>
    </div>
  );
};

const headerLogoStyle = `
  @media (max-width: 700px) {
    display: none;
  }
`;

const menuButtonStyle = `
  display: block;
  @media (min-width: 700px) {
    display: none;
  }
  color: white;
  font-size: 2rem;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 10px;
`;

const Header = ({ showContact, menuButtonHandler }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allMarkdownRemark(
        filter: { frontmatter: { title: { eq: "YourSolarQuotes" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              headerbuttontext
              headerimage {
                img {
                  childImageSharp {
                    fixed(width: 250) {
                      ...GatsbyImageSharpFixed_noBase64
                    }
                  }
                }
                alt
              }
            }
          }
        }
      }
      allHeaderYaml {
        edges {
          node {
            text
            link
          }
        }
      }
    }
  `);

  return (
    <StyledHeaderContainer bgColor="#02bbe7">
      <header css={headerContainerStyle}>
        <Link to="/" css={headerLogoStyle}>
          <Image
            fixed={
              data.allMarkdownRemark.edges[0].node.frontmatter.headerimage.img
                .childImageSharp.fixed
            }
            alt={
              data.allMarkdownRemark.edges[0].node.frontmatter.headerimage.alt
            }
          />
        </Link>
        <button css={menuButtonStyle} onClick={menuButtonHandler}>
          <FaBars />
        </button>
        {!showContact && (
          <nav css={navStyle}>
            <ul>
              {data.allHeaderYaml.edges.map(x => (
                <Link key={x.node.text} css={navLinkStyle} to={x.node.link}>
                  {x.node.text}
                </Link>
              ))}
            </ul>
          </nav>
        )}
        {showContact && <ContactNumber />}
        {showContact && <Or />}
        <Button id="cta-get-quotes">
          {data.allMarkdownRemark.edges[0].node.frontmatter.headerbuttontext}
        </Button>
      </header>
    </StyledHeaderContainer>
  );
};

Header.propTypes = {
  showContact: PropTypes.bool,
  menuButtonHandler: PropTypes.func
};

export default Header;
