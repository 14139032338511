import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { SocialIconLink } from "./SocialIconLink";

const SocialIconBlock = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-size: 2rem;
  > a {
    color: currentColor;
    padding-right: 1rem;
  }
`;

const SocialLinks = () => {
  const data = useStaticQuery(graphql`
    query SocialLinksQuery {
      socialLinks: allSocialYaml {
        edges {
          node {
            brand
            link
          }
        }
      }
    }
  `);

  return (
    <SocialIconBlock>
      {data.socialLinks.edges.map(({ node }) => (
        <SocialIconLink key={node.brand} {...node} />
      ))}
    </SocialIconBlock>
  );
};

export { SocialLinks };
